import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Form, FloatingLabel, Button } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { unsubscribe } from "../actions/NewsletterActions";
//import { PRODUCT_CREATE_RESET } from "../constants/productConstants";
import FormContainer from "../components/FormContainer";

const Unsubscribe = () => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const userUnsubscribe = useSelector((state) => state.userUnsubscribe);
  const { loading, success, error } = userUnsubscribe;

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!email) {
      alert('Please enter email')
    } else {
      dispatch(unsubscribe(email));
    }
  };
      

  return (
    <Row
      style={{
        backgroundColor: "#0b2603",
        maxWidth: "1256px",
        margin: "auto",
        borderRadius: "16px",
        marginTop: "24px",
        paddingBottom: "24px",
      }}
    >
      <FormContainer style={{ marginTop: "-2em" }}>
        <h2
          style={{
            textAlign: "center",
            color: "white",
            paddingTop: "24px",
          }}
        >
          Stop my Agrolinka Newsletter subscription
        </h2>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message dismissible variant="danger" duration={10}>
            {error}
          </Message>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <FloatingLabel controlId="email" label="Email" className="mb-3">
                  <Form.Control
                    size="lg"
                    placeholder="Email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              {success && <Message variant='success' >{<p>You will no longer receive Newsletters from Agrolinka</p>}</Message>}
              <div className="d-flex">
                <Button
                  type="submit"
                  className="my-1 ms-auto"
                  style={{
                    borderRadius: "8px",
                    marginBottom: "16px",
                    backgroundColor: "green",
                    width: "200px",
                    padding: "12px",
                    border: "none",
                    margin: "auto",
                  }}
                >
                  Unsubscribe
                </Button>
              
              </div>
            </form>
          </>
        )}
      </FormContainer>
    </Row>
  );
};

export default Unsubscribe;
