import {
  STORE_DETAILS_FAILURE,
  STORE_DETAILS_SUCCESS,
  STORE_DETAILS_REQUEST,
} from "../constants/storeConstants";


export const storeDetailsReducer = (
	state = { store : { } },
	action
) => {
	switch (action.type) {
		case STORE_DETAILS_REQUEST:
			return { store: { }, loading: true };
		case STORE_DETAILS_SUCCESS:
			return { loading: false, store: action.payload };
		case STORE_DETAILS_FAILURE:
			return { loading: false, error: action.payload };
		default:
			return { ...state };
	}
};