import {
  USER_SUBSCRIBE_REQUEST,
  USER_SUBSCRIBE_SUCCESS,
  USER_SUBSCRIBE_FAILURE,
  USER_UNSUBSCRIBE_REQUEST,
  USER_UNSUBSCRIBE_SUCCESS,
  USER_UNSUBSCRIBE_FAILURE
} from "../constants/NewsletterConstants";


export const userSubscribeReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_SUBSCRIBE_REQUEST:
			return {
				loading: true,
			};
		case USER_SUBSCRIBE_SUCCESS:
			return {
				loading: false,
                success: true,
				email: action.payload,
			};
		case USER_SUBSCRIBE_FAILURE:
			return {
				loading: false,
                success: false,
				error: action.payload,
			};
		default:
			return { ...state };
	}
};

export const userUnsubscribeReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_UNSUBSCRIBE_REQUEST:
			return {
				loading: true,
			};
		case USER_UNSUBSCRIBE_SUCCESS:
			return {
                loading: false,
				success: true
			
			};
		case USER_UNSUBSCRIBE_FAILURE:
			return {
				loading: false,
				error: action.payload,
			};
		default:
			return { ...state };
	}
};