import React from "react";
import { Row } from "react-bootstrap";

const About = () => {
  return (
    <Row style={{ maxWidth: "1024px", margin: "auto", textAlign: "justify" }}>
      <h1>About Us</h1>
      <p>
        OUR VISION <br/>
         Agrolinka is shaping a future where every African has easy
        access to quality food by fostering a business where farmers and food
        processors are guaranteed the best value for their hard work. <br/>
         OUR
        MISSION <br/>
        To Provide Africans quick access to quality food (packaged foods
        and fresh farm produce) at very fair prices using technology and data.<br/>
        WHY WE DO WHAT WE DO <br/>
        Agrolinka was born to tackle the inefficiency in
        the food distribution industry in Africa. Africa loses about 60% of food
        to waste annually and this is as a result of supply chain inefficiencies
        in the different value chains. We connect farmers and food processors to
        buyers and consumers, We do not just stop at that, we provide
        warehousing facilities, cold hubs and logistics services for our
        sellers. This goes to show we are more than your regular market place.
        All this we do from our single integrated back office. We believe in
        quality, customer satisfaction, and timeliness. We are your best
        agro-food market place. The agritech evolution is here! We are proud to
        be making a mark.
      </p>
    </Row>
  );
};

export default About;
