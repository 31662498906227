import React from 'react'
import { Image } from 'react-bootstrap'


const AppBanner = () => {
  return (
      
        <Image
						style={{
                            maxWidth: "1256px",
                            margin: "auto",
            
                          }}
						src='https://res.cloudinary.com/agrolinka-com/image/upload/v1654541105/assets/Group_11_1_cddhik.png'
                        />
               
      

  )
}

export default AppBanner