import React from "react";
import { Row } from "react-bootstrap";

const Terms = () => {
  return (
    <Row style={{ maxWidth: "1024px", margin: "auto", textAlign: "justify" }}>
      <h1>Terms of use</h1>
      <p>
        These Terms of Use sets forth the agreement between you and AgroLinka.
        It governs your use of the products and services we offer through our
        website and applications (collectively the "Site" or "AgroLinka
        Platform"). If you participate in the requests or displays of goods (the
        "AgroLinka Shop") on the AgroLinka Platform, then your agreement to
        these terms also includes your agreement with AgroLinka Shop. AgroLinka
        and AgroLinka Shop collectively will be referred to as "AgroLinka" "we"
        or "us" in this agreement.
      </p>
      <p>
        <b>
          This agreement provides important information to you, including
          information about your obligations about your content, our limitation
          of liability to you, and your agreement to resolve any disputes by
          individual arbitration and to waive the right to participate in a
          class action.
        </b>
        Please make sure to read this agreement, because your use of the Site is
        consent to these terms. If you do not agree to any of the updated terms,
        you should stop using the AgroLinka Platform.
      </p>
      <h2>1. Using the AgroLinka Platform.</h2>
      <p>
        <b>Who can use it</b>.Use of the AgroLinka Platform by anyone under 13
        years of age is strictly prohibited!
      </p>
      <p>
        <b>Registration</b>.Access to certain functionalities of the AgroLinka
        Platform will require you to register with us. If you register with
        AgroLinka, you agree to provide us accurate information and update it as
        needed for accuracy. We will treat personally identifiable information
        you provide as part of registration in accordance with ourPrivacy
        Policy.
      </p>
      <p>
        <b>Privacy Policy.</b>Our privacy practices are set forth in ourPrivacy
        Policy. By use of the AgroLinka Platform, you agree to accept thePrivacy
        Policy, regardless of whether you are a registered user.
      </p>
      <p>
        <b>Profile for Professionals.</b>If you are a professional services
        provider, you may set up a Professional Profile. If you do, you give us
        the right to list your Professional Profile in our directory of service
        providers, and you will be able to connect with others on the AgroLinka
        Platform while identified by your professional profile.
      </p>
      <p>
        <b>Acceptable Use Policy.</b>When using the AgroLinka Platform, you
        agree to abide by common standards of etiquette and act in accordance
        with the law, as described further in our "Acceptable Use Policy.”
      </p>
      <p>
        <b>Prohibited Products Policy.</b>If you are a professional, a vendor,
        or a seller, who is authorized to offer products through the AgroLinka
        Shop, you agree to abide by the "Prohibited Products Policy.”
      </p>
      <p>
        <b>Termination.</b>We may permanently or temporarily suspend your use of
        the site or the service at any time for any reason, without any notice
        or liability to you. We may terminate your account at any time for any
        or no reason, including if you violate any AgroLinka policy (including
        theAcceptable Use Policy.orProhibited Products Policy). Upon termination
        of your use of the service, certain provisions will survive termination,
        as detailed in Section 9(m).
      </p>
      <p>
        <b>Feedback.</b>We welcome your feedback and suggestions about how to
        improve the AgroLinka Platform. Feel free to submit feedback at
        support@AgroLinka.com. By submitting feedback in this or inanyother
        manner to us, you agree to grant us the right, at our discretion, to
        use, disclose and otherwise exploit the feedback, in whole or part,
        without any restriction or compensation to you.
      </p>
      <h2>2. Your Content.</h2>
      <p>
        <b>Definition of Your Content.</b>The AgroLinka Platform enables you to
        post material, including without limitation profile pictures, and
        messages. You may also post reviews of third-party service providers,
        third-party products, or third-party services ("Reviews"). All material
        that you display on the AgroLinka Platform will be referred to
        collectively as "Your Content." You acknowledge and agree that, as part
        of using the AgroLinka Platform, Your Content may be viewed by the
        general public and will not be treated as private or confidential.
      </p>
      <p>
        <b>License and Permission to Use Your Content.</b>You hereby grant to us
        and our affiliates, licensees and sublicensees, without compensation to
        you or others, a non-exclusive, perpetual, irrevocable, royalty-free,
        fully paid-up, worldwide license (including the right to sublicense
        through multiple tiers) to use, reproduce, process, adapt, publicly
        perform, publicly display, modify, prepare derivative works, publish,
        transmit and distribute Your Content, or any portion thereof, throughout
        the world in any format, media or distribution method (whether now known
        or hereafter created) for the duration of any copyright or other rights
        in Your Content. Such permission will be perpetual and may not be
        revoked for any reason, to the maximum extent permitted by law. Further,
        to the extent permitted under applicable law, you waive and release and
        covenant not to assert any moral rights that you may have in Your
        Content. If you identify yourself by name or provide a picture or audio
        or video recording of yourself, you further authorize us and our
        affiliates, licensees and sublicensees, without compensation to you or
        others, to reproduce, print, publish and disseminate in any format or
        media (whether now known or hereafter created) your name, voice and
        likeness throughout the world, and such permission will be perpetual and
        cannot be revoked for any reason, except as required by the applicable
        law. You further agree that we may use Your Content in any manner that
        we deem appropriate or necessary.
      </p>
      <p>
        <b>Ownership.</b>
        <br />
        We acknowledge and agree that you, or your licensors, as applicable,
        retain ownership of any and all copyrights in Your Content, subject to
        the non-exclusive rights granted to us in the paragraph above, and that
        no ownership of such copyrights is transferred to us under this
        agreement. Further, with respect to Your Content in the form of photos,
        and subject to AgroLinka product and user experience considerations: (a)
        we will use commercially reasonable efforts to maintain the attribution
        of such photos as submitted by you, and (b) we will not license or
        sublicense to third parties individual photos or collections of photos,
        except in each case for AgroLinka Business Purposes. "AgroLinka Business
        Purposes" means any use in connection with a AgroLinka-branded or
        co-branded website, application, publication or service, or any use
        which advertises, markets or promotes the AgroLinka Platform, the
        services or the information it contains, AgroLinka, or its affiliates.
        AgroLinka Business Purpose specifically includes the use of Your Content
        on the AgroLinka Platform in connection with features and functions
        offered by AgroLinka to our users that enable them to view and interact
        with Your Content (such as Sketch, View in My Room and the embed tool).
      </p>
      <p>
        <b>Your Responsibilities for Your Content.</b>
        <br />
        By posting Your Content on the AgroLinka Platform, you represent and
        warrant to us that you have the ownership rights, or you have obtained
        all needed licenses or permissions from any necessary parties, to use
        Your Content in this manner. This includes obtaining the right to grant
        us the rights to the use of Your Content in accordance with this
        agreement. You are in the best position to judge whether Your Content is
        in violation of intellectual property or personal rights of any
        third-party.You accept full responsibility for avoiding infringement of
        the intellectual property or personal rights of others in connection
        with Your Content.You are responsible for ensuring that your content
        does not violate AgroLinka'sAcceptable Use Policy,Prohibited Products
        Policyor any applicable law or regulation. You agree to pay all
        royalties, fees and any other monies owed to any person by reason of
        Your Content.
      </p>
      <p>
        <b>Limits.</b>
        <br />
        We reserve the right to edit, abridge, or remove Your Content, in whole
        or part, for any reason (which may include a reported violation of
        ourAcceptable Use PolicyorProhibited Products Policy. We reserve the
        right to edit, refuse to post or remove Your Content submitted by you
        for any reason without notice. We do not guarantee that we will publish
        all of Your Content. If you seek to publish a Review, the requirements
        will be governed by ourReview Policy.
      </p>
      <h2>3. Our Content and Materials.</h2>
      <p>
        <b>Definition of Our Content and Materials.</b>
        <br />
        All intellectual property in or related to the AgroLinka Platform
        (specifically including, but not limited to our software, the AgroLinka
        marks, the AgroLinka logo, and AgroLinka buttons, badges, and widgets,
        but excluding Your Content), is the property of AgroLinka Ltd., its
        subsidiaries and affiliates or its licensors ("Our Content and
        Materials”).
      </p>
      <p>
        <b>Our License to You.</b>
        <br />
        Subject to these terms of use, including the restrictions below, we
        grant you a limited, non-exclusive license to use and access Our Content
        and Materials in connection with your use of the AgroLinka Platform.
        Except as expressly agreed to otherwise by us (such as your eligibility
        for creation of a Professional Profile or your entering into a vendor,
        seller, advertiser, or other agreement with us), your use of the
        AgroLinka Platform must be limited to personal, non-commercial use. We
        may terminate this license at any time for any reason. Except for the
        rights and license granted in these terms, we reserve all other rights
        and grant no other rights or licenses, implied or otherwise.
      </p>
      <p>
        <b>No Endorsement or Verification.</b> <br />
        Please note that the AgroLinka Platform enables access to third-party
        content, products and services, and it offers interactions with
        third-parties over which we have no control. We assume no responsibility
        for, nor do we endorse or verify the content, offerings or conduct of
        third-parties (including but not limited to the products or services
        offered by third-parties or the descriptions of the products or services
        offered by third-parties). Participation or availability on the
        AgroLinka Platform does not amount to endorsement or verification by us.
        We make no warranties or representations with respect to the accuracy,
        completeness or timeliness of any content posted on the AgroLinka
        Platform by anyone.
      </p>
      <p>
        <b>Restrictions.</b> <br /> Except as expressly provided in these terms,
        you agree not to use, modify, reproduce, distribute, sell, license,
        reverse engineer, decompile, or otherwise exploit Our Content and
        Materials without our express written permission. AgroLinka's permission
        to you for your use of the AgroLinka Platform expressly excludes
        commercial use by you of any information concerning product descriptions
        or professional listings for the benefit of another merchant. You
        expressly are prohibited from any use of data mining, robots, or similar
        data gathering and extraction tools in your use of the AgroLinka
        Platform. You may view and print a reasonable number of copies of web
        pages located on the AgroLinka Platform for your own personal use,
        provided that you retain all proprietary notices contained in the
        original materials, including attribution to AgroLinka.
      </p>
      <p>
        <b>Ownership.</b>
        <br />
        You acknowledge and agree that the AgroLinka Platform and AgroLinka
        marks will remain the property of AgroLinka. The content, information
        and services made available on the AgroLinka Platform are protected by
        Nigeria copyright, trademark, and other laws, and you acknowledge that
        these rights are valid and enforceable. You acknowledge that you do not
        acquire any ownership rights by using the AgroLinka Platform.
      </p>
      <h2>4. Other Offerings on the AgroLinka Platform.</h2>
      <p>
        <b>Third-Party Services.</b>
        <br />
        You may be provided the opportunity on the AgroLinka Platform to
        purchase services that are offered by third parties (collectively "Third
        Party Services"), including those offered by professionals registered
        with Professional Profiles on the AgroLinka Platform. The availability
        of any Third Party Services on the AgroLinka Platform does not imply our
        endorsement of the Third Party Services.
      </p>
      <p>
        <b>Third-Party Sites.</b>
        <br />
        The AgroLinka Platform may contain links to other websites (the
        "Third-Party Sites") for your convenience. We do not control the linked
        websites or the content provided through such Third-Party Sites. Your
        use of Third-Party Sites is subject to the privacy practices and terms
        of use established by the specific linked Third-Party Site, and we
        disclaim all liability for such use. The availability of such links does
        not indicate any approval or endorsement by us.
      </p>
      <h2>
        5. Reporting Violations of Your Intellectual Property Rights, AgroLinka
        Policies, or Applicable Laws
      </h2>
      <p>
        We have a special process for reporting violations of your intellectual
        property rights or other violations of AgroLinka policies or applicable
        laws.
      </p>
      <p>
        <b>Copyright and Trademark Policy.</b>
        <br />
        If you are a holder of intellectual property rights or a person
        authorized to act in the name of a holder of intellectual property
        rights and you reasonably believe that information which is posted to
        the AgroLinka Platform someway infringes your intellectual property
        rights or intellectual property rights of a person, in which name you
        act, you may provide notification to theresource@AgroLinka.comrequiring
        to delete such material. In this regard you shall warrant that your
        appeal has a legal basis, and you act in good faith according to law.
      </p>
      <p>
        Reports of Other Violations.If you believe content on the AgroLinka
        Platform violates AgroLinka'sAcceptable Use Policy,Prohibited Products
        Policy., or otherwise violates applicable law (apart from copyright or
        trademark violations), you may submit the following
        resource@AgroLinka.com.{" "}
      </p>
      <p>
        We have no obligation to delete content that you personally may find
        objectionable or offensive. We endeavor to respond promptly to requests
        for content removal, consistent with our policies described above and
        applicable law.
      </p>
      <h2>6. Disclaimers and Limitations of Liability.</h2>
      <p>
        <b>
          PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF
          AGROLINKA ENTITIES TO YOU.
        </b>
        <br />
        IN NO EVENT WILL THE AGROLINKA PLATFORM BE LIABLE TO YOU OR ANY THIRD
        PARTY FOR ANY COST OF COVER, LOST PROFITS OR DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER
        ARISING OUT OF, BASED ON, OR RESULTING FROM YOUR USE OF THE SERVICES AND
        YOUR USE OF, OR TRANSACTIONS WITH, AGROLINKA, USERS, PROFS OR OTHER
        THIRD PARTIES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE OR
        ANY OTHER LEGAL THEORY, WHETHER OR NOT SUCH DAMAGES ARE FORESEEABLE AND
        WHETHER OR NOT AGROLINKA IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
        PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. <br />
        Any claims relating to use of the Services must be brought within one
        (3) months from the date the cause of action arose. Claims brought after
        such period will be VOID.
      </p>
      <h2>7. Indemnification.</h2>
      <p>
        IN ADDITION TO YOUR INDEMNIFICATION OBLIGATIONS WITH RESPECT TO FALSE
        INFORMATION (DESCRIBED ABOVE), YOU AGREE TO INDEMNIFY, DEFEND AND HOLD
        HARMLESS THE PORCH PARTIES FROM AND AGAINST ANY CLAIMS, DISPUTES,
        DEMANDS, LOSSES, OBLIGATIONS, LIABILITIES, EXPENSES, DAMAGES AND COSTS
        (INCLUDING BUT NOT LIMITED TO ATTORNEY'S FEES) DUE TO OR ARISING OUT OF
        (1) YOUR ACCESS TO OR USE OF THE SERVICES, (2) YOUR USE OF, OR
        TRANSACTIONS WITH, AGROLINKA, USERS, PROFS OR OTHER THIRD PARTIES, OR
        (3) ANY OTHER VIOLATION BY YOU OF ANY OF THESE TERMS. AGROLINKA RESERVES
        THE RIGHT, AT ITS OWN COST AND SOLE DISCRETION, TO ASSUME THE EXCLUSIVE
        DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION
        BY YOU, IN WHICH EVENT YOU WILL FULLY COOPERATE WITH PORCH IN ASSERTING
        ANY AVAILABLE DEFENSES.
      </p>
      <h2>8. Dispute Resolution.</h2>
      <p>
        <b>Arbitration.</b>
        <br />
        You and AgroLinka agree that any dispute, claim or controversy arising
        out of or relating to the this agreement or to your use of the AgroLinka
        Platform (collectively "Disputes") will be settled by binding
        arbitration, except that each party retains the right to seek injunctive
        or other equitable relief in a court of competent jurisdiction to
        prevent the actual or threatened infringement, misappropriation, or
        violation of a party's copyrights, trademarks, trade secrets, patents,
        or other intellectual property rights. You acknowledge and agree that
        you and AgroLinka each are waiving the right to a trial by jury or to
        participate as a plaintiff as a class member in any class action
        proceeding. Further, unless you and AgroLinka agree otherwise in
        writing, the arbitrator may not consolidate more than one person's
        claims and may not preside over any form class action proceeding. If
        this specific paragraph is held unenforceable, then the entirety of this
        "Dispute Resolution" Section 8 will be deemed void. Except as provided
        in the preceding sentence, this "Dispute Resolution" Section 8 will
        survive any termination of the Terms.{" "}
      </p>
      <p>
        <b>Arbitration Rules and Governing Law. </b>
        <br />
        You agree that these arbitration will be governed solely by Nigeria and
        Lagos Court of arbitration (LCA) law, without regard to conflict of law
        provisions. Without limiting the jurisdiction of any other court, you
        admit and further irrevocably agree to submit to the personal
        jurisdiction of the courts located within Lagos State, for the purpose
        of entering judgments on arbitral awards
      </p>
      <p>
        <b>Arbitration Process.</b>
        <br />
        A party who desires to initiate the arbitration must provide the other
        party with a written Demand for Arbitration as specified in the Lagos
        Court of Arbitration Rules. The arbitrator will be an attorney licensed
        to practice law in Lagos State and will be selected by the parties from
        the LCA's roster of consumer dispute arbitrators. If the parties are
        unable to agree upon an arbitrator within 7 days of delivery of the
        Demand for Arbitration, then the LCA will appoint the arbitrator in
        accordance with LCA Rules.
      </p>
      <p>
        <b>Arbitration Location and Procedure.</b>
        <br />
        Unless you and AgroLinka agree otherwise, the arbitration will be
        conducted in Lagos Sate, Nigeria.
      </p>
      <p>
        <b>Arbitrator's Decision. </b>
        <br />
        The arbitrator will render an award within the timeframe specified in
        the LCA Rules. The arbitrator's decision will include the essential
        findings and conclusions upon which the arbitrator based the award.
        Judgment on the arbitration may be entered in any court having
        jurisdiction thereof. Any award of damages by an arbitrator must be
        consistent with the "Disclaimers and Limitations of Liability" section
        above. The arbitrator may award declaratory or injunctive relief in
        favor of the claimant only to the extent necessary to provide relief
        warranted by the claimant's individual claim.
      </p>
      <p>
        <b>Fees. </b>
        <br />
        Your responsibility to pay any LCA filing, administrative and arbitrator
        fees will be as set forth in the LCA Rules.
      </p>
      <h2>9. Miscellaneous.</h2>
      <p>
        <b>Supplemental Terms for Certain Services. </b>
        <br />
        Certain services offered on the AgroLinka Platform may require you to
        enter into a separate agreement and/or be subject to additional terms.
        For example: placement of advertisements, participation in brand
        services, or offering products for sale in the AgroLinka Shop each
        require you to enter into a separate agreement with terms specific to
        that service. In the event of any conflict between this agreement and
        the terms of that separate agreement, the terms of that separate
        agreement will control.
      </p>
      <p>
        <b>Application Provider Terms.</b>
        <br />
         If you access the AgroLinka Platform through a AgroLinka application,
        you acknowledge that this agreement is between you and AgroLinka only,
        and not with another application service or application platform
        provider (such as Apple, Inc. or Google Inc.), which may provide you the
        application subject to its own terms.
      </p>
      <p>
        <b>Controlling Law and Jurisdiction. </b> <br />
        This agreement will be interpreted in accordance with the laws of the
        Nigeria, without regard to their conflict-of-law provisions. You and we
        agree to submit to the personal jurisdiction of a federal or state court
        located in Lagos State, Nigeria for any actions for which the
        arbitration provision, as set forth in the Dispute Resolution provision
        above, does not apply.
      </p>
      <p>
        <b>Export.</b>
        <br />
        The AgroLinka Platform is controlled and operated from our Nigeria
        offices in Lagos. AgroLinka software is further subject to Nigeria
        export controls. No software for AgroLinka may be downloaded or
        otherwise exported or re-exported in violation of any applicable laws or
        regulations.
      </p>
      <p>
        <b>Changes. </b>
        <br />
        We reserve the right at any time to:
        <br />
        i. change the terms and conditions of this agreement, consistent with
        applicable law;
        <br />
        ii. change the AgroLinka Platform, including eliminating or
        discontinuing any information or services or other features in whole or
        in part; and
        <br />
        iii. deny or terminate your AgroLinka account, or use of and access to
        the AgroLinka Platform.
      </p>
      <p>
        Any changes we make to the terms and conditions will be effective
        immediately upon our making such changes available on the AgroLinka
        Platform, and posting notice of such changes on the AgroLinka Platform
        or in another manner in our reasonable discretion. You agree that your
        continued use of the AgroLinka Platform after such changes constitutes
        your acceptance of such changes. If you do not agree with any of the
        updates to this agreement, you should not use the AgroLinka Platform. Be
        sure to return to this page periodically to ensure your familiarity with
        the most current version of the terms of use.
      </p>
      <p>
        <b>Languages.</b>
        <br />
        English is the authoritative text of this agreement and all
        communications, notices, arbitrations and other actions and proceedings
        relating to this agreement will be made and conducted in English, even
        if we choose to provide translations of this agreement into the native
        languages in certain countries.
      </p>
      <p>
        <b>Assignment.</b>
        <br />
        No terms of this agreement, nor any right, obligation, or remedy
        hereunder is assignable, transferable, delegable, or sublicensable by
        you except with AgroLinka's prior written consent, and any attempted
        assignment, transfer, delegation, or sublicense shall be null and void.
        AgroLinka may assign, transfer, or delegate this agreement or any right
        or obligation or remedy hereunder in its sole discretion.
      </p>
      <p>
        <b>Waiver. </b>
        <br />
        Our failure to assert a right or provision under this agreement will not
        constitute a waiver of such right or provision.
      </p>
      <p>
        <b>Headings.</b>
        <br />
        Any heading, caption, or section title contained is inserted only as a
        matter of convenience and in no way defines or explains any section or
        provision hereof.
      </p>
      <p>
        <b>Further Assurances. </b>
        <br />
        You agree to execute a hard copy of this agreement and any other
        documents, and take any actions at our expense that we may request to
        confirm and effect the intent of this agreement and any of your rights
        or obligations under this agreement.
      </p>
      <p>
        <b>Entire Agreement/ Severability. </b>
        <br />
        This agreement supersedes all prior terms, agreements, discussions and
        writings regarding the AgroLinka Platform and constitutes the entire
        agreement between you and us regarding the AgroLinka Platform. If any
        provision in this agreement is found to be unenforceable, then (except
        as expressly provided otherwise in Section 8(a) (Dispute Process)) that
        provision will not affect the enforceability of the remaining provisions
        of the agreement, which will remain in full force and effect.
      </p>
      <p>
        <b>Survival: </b>
        <br />
        The following provisions will survive expiration or termination of this
        agreement: Section 2 (Your Content) Section 3(d)(Restrictions) and
        3(e)(ownership), Section 6 (Disclaimers and Limitations of Liability),
        Section 7 (Indemnification), Section 8 (Dispute Resolution) and Section
        9 (Miscellaneous).
      </p>
      <p>
        <b>Contact. </b>
        <br />
        Feel free to contact us by visiting support@agrolinka.com with any
        questions about this agreement. The AgroLinka Platform is operated by: 
      
        Longan Agro Ltd
        <br />
        34 Inuwa Wada Street,
        <br />
        Utako, Abuja,
        <br />
        Nigeria
      </p>
    </Row>
  );
};

export default Terms;
