/* eslint-disable array-callback-return */
import React, {useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	ListGroup,
} from 'react-bootstrap';
//import ImageMagnifier from '../components/ImageMagnifier'; // to magnify image on hover
import Meta from '../components/Meta';
import Loader from '../components/Loader';
import Message from '../components/Message';



//import { refreshLogin, getUserDetails } from '../actions/userActions';

//import getDateString from '../utils/getDateString';
import '../styles/product-page.css';

import { getStore } from '../actions/storeActions';

import StoreProduct from '../components/StoreProduct';


const StorePage = ({ history, match }) => {

	const dispatch = useDispatch();

    const storeName = match.params.storeName

    const storeDetails = useSelector((state) => state.storeDetails)
    const { store, loading, error } = storeDetails


	// const userLogin = useSelector((state) => state.userLogin);
	// const { userInfo } = userLogin;

	// const productDetails = useSelector((state) => state.productDetails);
	// const { loading, product, error } = productDetails;

	// const userDetails = useSelector((state) => state.userDetails);
	// const { error: userLoginError } = userDetails;

	

    useEffect(() => {
        dispatch(getStore(storeName))
    }, [dispatch, storeName])

	// fetch user login info
	// useEffect(() => {
	// 	userInfo
	// 		? userInfo.isSocialLogin
	// 			? dispatch(getUserDetails(userInfo.id))
	// 			: dispatch(getUserDetails('profile'))
	// 		: dispatch(getUserDetails('profile'));
	// }, [userInfo, dispatch]);

	// // refresh the access tokens for accessing user details
	// useEffect(() => {
	// 	if (userLoginError && userInfo && !userInfo.isSocialLogin) {
	// 		const user = JSON.parse(localStorage.getItem('userInfo'));
	// 		user && dispatch(refreshLogin(user.email));
	// 	}
	// }, [userLoginError, dispatch, userInfo]);



	
	// useEffect(() => {
	// 	if (product && product.reviews && userInfo) {
	// 		let flag = 0; // to check if this user has already reviewed this product
	// 		for (let review of product.reviews) {
	// 			if (review.user === userInfo.id) {
	// 				flag = 1;
	// 				break;
	// 			}
	// 		}
	// 		flag ? setShowReviewForm(false) : setShowReviewForm(true);
	// 	} else {
	// 		setShowReviewForm(true);
	// 	}
	// }, [product, userInfo]);

	

	

	return (
		<>
			{/* <Link className='go-back' to='/' >
				Go Back
			</Link> */}
            <p></p>
			{loading && <Loader/>}
			{store && (!store.storeName || store.storeName !== match.params.storeName) ? (
                <Loader />
            ) : error ? (
                <Message dismissible variant='danger' duration={10}>
					{error}
				</Message>
            ) : store ? (
                <>
                    	<Meta title={`${store.storeName}`} />
                        <Row style={{borderRadius: '16px'}}>
                            <Col md={8}  >
                                <ListGroup>
                                    <ListGroup.Item>
                                        <strong>Store: </strong>
                                        {store.storeName}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>Description:</strong>{' '}
                                        {store.description}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>Email:</strong>{' '}
                                        {store.email}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>Phone Number:</strong>{' '}
                                        {store.phoneNumber}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            
                            <Col md={4}>
                            <ListGroup>
                                    <ListGroup.Item>
                                        <strong>Address: </strong>
                                        {store.address}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>Region:</strong>{' '}
                                        {store.region}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>State:</strong>{' '}
                                        {store.state}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>Country:</strong>{' '}
                                        {store.country}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                       
                        <hr/>

                        <Row>
                        {store.storeProducts.length
							? store.storeProducts.map((product) => {
									return (
										<Col
											sm={12}
											md={6}
											lg={4}
											xl={3}
											key={product._id}>
											<StoreProduct product={product}/>
										</Col>
									);
							  })
							: 
									(<Col className='text-center'>
									<p>This Seller has no products yet</p>
									</Col>
							  )}
					</Row>

                    
                </>
            ) : <p>Nothing</p>}
                            
		
			
		</>
	);
};

export default StorePage;
