import React from "react";
import { Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Row style={{maxWidth: '1024px', margin: 'auto', textAlign: 'justify'}}>
      <h1>Privacy Policy</h1>
      <p>
        This Agrolinka Privacy Policy is to help you understand what information
        Agrolinka collects, how Agrolinka uses it, how it may be shared, and
        what choices you have. This privacy policy governs the products and
        services we offer through our website, and other applications
        (collectively the "Site" or "Agrolinka Platform"). When you use the
        Site, you consent to our collection, use and disclosure of information
        about you as described in this privacy policy. Additionally, our Terms
        of Use are incorporated by reference into this privacy policy, including
        applicable terms governing limitations on liability and the resolution
        of disputes. The terms "we," or "our," refer to Agrolinka LTD., and
        Agrolinka Shop, which are headquartered in Lagos States.
      </p>


      <h2>1. Collection of Information. </h2>
      <p>We collect information (i) from you
      directly; (ii) from third parties, and (iii) automatically when you use
      our Site. In general, we collect information as follows: 
      <p>
      • Account and Profile Information: When you create an account on the Agrolinka Platform,
      we collect your name, contact information, and other information you
      provide. You also will be asked to create a profile on the Site. If you
      do, profile information such as your name, username, current projects,
      demographic information, and any photo you submit may be displayed
      publicly on the social network. You can customize some of the information
      displayed publicly in your Agrolinka profile by going to your account and
      profile settings. If you feel that an unauthorized account has been
      created depicting you or your likeness, please contact us
      at support@Agrolinka.com. You have an obligation to provide us accurate
      information.  
      </p>
      
      <p>
      •
      Public Content: We collect and store the information that
      you post to the Site, including your photos, comments, projects, and
      profile information.
      </p>
      
      <p>
         • Communications. When you communicate with us (via
      email, phone, through the Site or otherwise), we maintain a record of your
      communication (including call recordings). Similarly, when you submit
      information to a Agrolinka Pros through their Agrolinka Pros Profile, we
      may collect and maintain that information on the Pros's behalf; however,
      the Pross use of that information (including call recordings) is subject
      to their own policies and practices, not this privacy policy. 
      </p>
      
      <p>•
      Messages. If you exchange messages with others through the Site, in
      accordance with applicable law, we may store them in order to process and
      deliver them, allow you to manage them, and investigate possible
      wrongdoing in connection with the Site. The Agrolinka Platform may also
      enable you to connect, at your initiation, with third-party providers of
      products and services who you wish to contact outside of the Site; any
      such information you submit or send to third parties in this manner,
      including your personal email address, is not subject to this Privacy
      Policy.
      </p>
      
      <p> • Transactional Information. If you contact seller within the
      Site, we collect certain information in order to process your request,
      such as your phone number, address, email.
      </p>
      
      <p>
        • Site Activity and
      Automatically Collected Information. We collect and may store information
      about your use of the Site (including our mobile applications), such as
      your search activity, the pages you view, and the date and time of your
      visit. We also collect and may store information that your computer or
      mobile device provides to us in connection with your use of the Site, such
      as your browser type, type of computer or mobile device, browser language,
      IP address, mobile carrier, unique device identifier, location, and
      requested and referring URLs.
      </p> 
      
      <p>
         • Linked Networks and Contacts. If you elect
      to connect your Agrolinka profile to another network or service provider
      like Facebook or Google, you will be asked to enable sharing of your
      contact information with Agrolinka. If you agree to this sharing, in order
      to facilitate a more connected experience, we may send notices so that you
      and your contacts can find and follow each other on the Agrolinka
      Platform. You also will be connected within the Site as followers of each
      other, and you can learn more about people you know in common on the Site.
      You can adjust permission for users to follow your profile in the advanced
      settings of your profile.</p>
      
      </p>
      <h2>2. Use of Information. </h2>
      <p>We may use the
      information we collect for purposes such as to: provide the Site's
      functionality; improve the Site's quality; personalize your experience;
      tailor the content and information that we may send or display to you;
      offer location customization, and otherwise offer personalized help and
      suggestions; track usage of the Site; facilitate the fulfillment of your
      orders placed through the Site; provide feedback to businesses and
      professionals whose products or services are listed on the Site; display
      relevant advertising (on our Site and third party sites); evaluate the
      success of our advertising; provide customer support; communicate with you
      about your use of the Site and any orders; allow third parties to contact
      you through the Site; respond to your inquiries and requests; send you
      marketing communications and otherwise message you (in ways described by
      this privacy policy and managed by you in your account settings); back up
      our systems and allow for disaster recovery; enhance the security of the
      Site; fulfill transactions authorized by you on the Site; use as otherwise
      requested or consented to by you; and to comply with legal obligations. In
      addition, we use the information we collect to better understand how users
      access and use our Site and services, both on an aggregated and
      individualized basis, in order to improve our Site and services, respond
      to user desires and preferences, develop additional products and services,
      and for other research and analytical purposes. From time to time, we may
      also offer contests or promotions, and will use the information we collect
      to administer such contests, promotions and surveys, to analyze the
      results for research purposes, and to comply with legal requirements
      related to such contests and promotions.</p>
      <h2> 3. Sharing Your Information</h2>
      <p> We do
      not rent or sell your personal contact information (such as your email
      address associated with your account registration) to third-parties to use
      for their own marketing purposes. However, in some cases Agrolinka may
      allow third parties, including other users and Agrolinka partners, to
      contact you through the Site, including for marketing purposes.
      Additionally any information that you share publically through your
      profile is available to third party users of and visitors to the Site; we
      cannot control such third parties' use or further disclosure of your
      information. We may share some information about you with third parties in
      connection with your use of the Site. Our information sharing with
      third-parties generally fall into these categories and purposes:<p> • Agents,
      Contractors, and Service Providers: We may share your information with our
      agents, e-commerce partners, and contractors or other service providers as
      needed to carry out the performance and functionality of the Site, fulfill
      your requests or authorized commercial transactions, process your
      payments, or provide customer support. </p></p> 
      <p><b>• Analytics and Usage:</b> We may share
      user information in de-identified or aggregate form, with third parties,
      such as advertisers, professionals, and other businesses. For example, we
      may disclose to a business the number of users that have viewed their
      product. Business or professionals that participate on the Agrolinka
      Platform may also access usage data associated with your public profile.
      For example, a business can see that you, as identified by your public
      profile username, any profile photo, and any other publicly displayed
      profile identifiers (but not identified by your email or non-public
      account information), added their product to one of your Ideabooks on a
      particular date.</p> <p>• Third Party Ad Networks: We use third-party advertising
      companies to serve advertisements on third party sites, and may work with
      them to serve ads on our Site, as well as to assist us in evaluating the
      effectiveness of our advertising, in accordance with applicable law. These
      companies use cookies, web beacons, and other tracking technologies to
      collect information (not including your name, address, email address or
      telephone number) about your visits to this Site and other sites in order
      to provide targeted advertisements to you. Please see the Third Party
      Advertising section below for more information. </p> 
      <p>• Affiliated Entities We
      may share information from or about you with subsidiaries, joint ventures,
      or other companies under common control, in which case we will require
      them to honor this privacy policy. </p><p>• Business Transfers: If we are
      acquired by or merged with another entity, if substantially all of our
      assets are transferred to another company, or as part of a bankruptcy
      proceeding, we may transfer the information we have collected about you to
      the other company. We may also disclose certain personal information to a
      company as part of the planning or review for a potential merger,
      acquisition or transfer of assets.</p> <p>• Other Users: Information that you
      post publicly on the Site (e.g., photos, comments, your profile) will be
      made publicly available to other users of the Agrolinka Platform. The
      information that you post to the Site is intended for public consumption,
      including your photos, comments, Ideabooks, and profile information. By
      posting on the Site, you agree to permit us to disclose publicly the
      material you post.</p>
      <p> • Investigations: We may investigate and disclose
      information from or about you, as permitted by applicable law, if we
      believe that such investigation or disclosure is (a) necessary to comply
      with legal process and law enforcement instructions and orders, such as a
      search warrant, subpoena, statute, judicial proceeding, or other legal
      process served on us; (b) helpful to prevent, investigate, or identify
      possible wrongdoing in connection with the Site; or (c) helpful to protect
      our rights, reputation, property, or that of our users, subsidiaries, or
      the public.</p>
      <h2> 4. Agrolinka Communications </h2>
      When you join the Agrolinka
      <p>Platform by signing up for an account or creating a profile, as part of
      the service you will receive the Agrolinka newsletter about the offerings
      and features on the Site. As permitted by law, you are also opting to
      receive other emails from us that may be of interest to you as a member of
      the Agrolinka community. We may also ask you to consent to receive text
      message communications from us. You can manage your email and notice
      preferences in account settings, but note that you cannot opt out of
      receiving certain administrative or legal notices from us. If you opt-out
      of receiving emails about recommendations or other information we think
      may interest you, we may still send you transactional e-mails about your
      account or any services you have requested or received from us. However,
      you may opt out entirely of receiving any text messages - promotional,
      transactional or otherwise - from us.</p>
      
      <p> Additionally, by joining, you are
      agreeing to receive feedback and communications from third-parties within
      the Site, including third-party providers of products or services who may
      comment on your postings within the Site. If you don't want third parties
      to see such information, you should not post it to your profile or the
      Site.</p>
      
      <h2>5. Cookies and Other Tracking Technologies</h2>
      
      <p> We, and third parties we
      interact with, including our third party service providers, use cookies in
      connection with your use of the Site and third party websites (Cookies are
      small data files that may have unique identifiers, and reside, among other
      places, on your computer or mobile device, in emails we send to you, and
      on our Site. We also use web beacons for similar purposes on our Site, and
      in HTML e-mails to our customers (e.g., to help us track e-mail response
      rates, identify when our e-mails are viewed, and track whether our e-mails
      are forwarded). In addition, we may use local shared objects (sometimes
      called "flash cookies"), java script, log files, and similar technologies
      (together with cookies and web beacons, collectively referred to as
      "Cookies" in this policy) to track and analyze site usage and facilitate
      advertising on our Site and third party sites. 
      </p>
      <p>
        We may use Cookies to
      collect or transmit information about you and your use of the Site or
      third-party sites, such as: your browser type, search preferences, data
      relating to advertisements that have been displayed to you or that you
      have clicked on, your interests on our Site and other sites, and the date
      and time of your use. When we use Cookies, we do so in order to further
      Site features and processes, provide authentication and security for your
      transactions on the Site, store your preferences, facilitate relevant
      advertising, and help us learn more about how users engage with the Site,
      and understand your activities after you leave our Site. Our Cookies may
      be persistent or stored only during an individual session. 
      </p>
      <p>
        You may opt-out
      of tracking by third parties for certain advertising purposes (as
      discussed) which can help you control targeted advertising by
      third-parties that track your activities across sites. It may be possible
      to disable certain Cookies through your device or browser settings. The
      method for disabling cookies may vary by device and browser, but can
      usually be found in preferences or security settings. However, doing so
      may affect your ability to use the Site. If you are not comfortable with
      the above uses of Cookies, please do not use this Site.</p>
      
      <p>
        Do-Not-Track
      Signals. Please note we do not change system behavior within the Site in
      response to browser requests not to be tracked. You may, however, disable
      certain tracking as discussed in this section (e.g., by disabling cookies
      or opting out of ad networks).</p>
      <p>
         Third-Party Analytics. We also use
      automated devices and applications, such as Google Analytics, to evaluate
      usage of our Sites and the services we provide; you can opt out of Google
      Analytics and review its privacy policy here. We use these tools to gather
      non-personal information about users to help us improve our services,
      performance and user experiences. These analytics providers may use
      Cookies to perform their services, and may combine the information that
      they collect about you on our Site with other information that they have
      collected. This policy does not cover such third parties' use of the data.</p>
      <h2>
      6. Third Party Advertising.</h2>
      <p> As noted, we work with third parties such as
      network advertisers to serve advertisements on third-party websites or
      other media, and to evaluate the success of our advertising campaigns,
      including Facebook, Google, and others (both Facebook and Google offer
      additional ways to opt out of certain advertising activities, and maintain
      their own privacy policies that address their interest-based advertising
      services). We also may work with third parties to display ads on our Site.
      </p>
      <p>
      Third-party ad network providers, advertisers, sponsors and/or traffic
      measurement services may use cookies, JavaScript, web beacons (including
      clear GIFs), Flash LSOs and other tracking technologies to measure the
      effectiveness of their ads and to personalize advertising content to you.
      This enables us and these third parties to target advertisements to you
      for products and services in which you might be interested, based upon
      your activities on our Site and other websites. These third-party cookies
      and other technologies are governed by each third party's specific privacy
      policy, not this one. We may provide these third-party advertisers with
      information about members, or allow them to collect certain information
      through our Site (e.g., IP address and demographic information), but we do
      not disclose your name, contact information or other personally
      identifiable information. 
      </p>
      <p>
        Opting out from one or more companies listed on
      the respective opt out pages noted above, will opt you out from those
      companies' delivery of interest-based content or ads to you based upon
      your activities on multiple websites, but it does not mean you will no
      longer receive any advertising through our Site or on other websites. You
      may continue to receive advertisements, for example, based on the
      particular website that you are viewing (i.e., contextually based ads).
      Also, if your browsers are configured to reject cookies, or you
      subsequently delete your cookies, your opt out may not be effective.
      Additional information is available on the websites listed above.
      </p>
      <h2> 7.
      Plug-ins and Social Media Widgets.</h2>
      <p> Our Site may include social media
      features and widgets, such as the Facebook Like button and the "Share
      this" button, as well as other interactive mini-programs that run both on
      our Site and Services and on third-party websites, and enable certain
      features to work (e.g., blogs and comments). These features may collect
      your IP address, which page you are visiting, and may set a cookie to
      enable the feature to function properly and for other purposes. When these
      features are displayed on our Site they are hosted by third parties, and
      are subject to their own privacy policies, not this one. Your interactions
      with these features are governed by the privacy policy of the company
      providing them.
      </p>
      <h2> 8. Pro Pages and Websites. </h2>
      
      <p>Professionals, Seller, and
      advertisers ("Agrolinka Pross"), who are independent third parties, may
      maintain profile pages on the Agrolinka Platform, and we may host
      Agrolinka Pros Websites on or through the Platform (collectively the "Pros
      Pages"). Agrolinka Pross may collect certain information through Pros
      Pages, and allow you to contact them through their Pros Pages. Pros's use
      of any information collected via Pros Pages, or submitted by you to Pross
      through the Agrolinka Platform, is subject to the Agrolinka Pros's own
      policies and practices, and not this privacy policy.
      </p>
      <h2> 9. Choice and Access</h2>
      <p>
      to Your Personal Information. You have certain choices about how to
      control your data, including publicly displayed profile information,
      communications settings, network linking, and opting out of tracking by
      third- parties for certain advertising purposes. These choices are
      described throughout this privacy policy in the relevant sections.
      Further, you may modify any account information that you have submitted by
      logging into your account and updating your profile information. Please
      note that copies of information that you have updated, modified or deleted
      may continue to reside in our systems for a period of time, and we may
      maintain copies as part of our business records.
      </p>
      <h2> 10. Account Termination</h2>
      <p>
      and Data Retention. You can close your account at any time by going to
      account settings. When you close your account, you will cease to provide
      ongoing or future data to us. Your public profile will be deactivated.
      However, unless prohibited by applicable law, postings or comments you
      provided to the Site prior to closing your account, will remain public. We
      also may retain information about you for the purposes authorized under
      this privacy policy, including a copy of your account information as part
      of our business records, unless prohibited by law.
      </p>
      <h2> 11. Children.</h2>
      <p>The Site
      is intended for general audiences and is not directed to children under
      13. We do not knowingly collect personal information from children under
      13. If you become aware that a child has provided us with personal
      information without parental consent, please contact us at
      privacy@Agrolinka.com, and we will take steps to remove such information
      and terminate the child's account.
      </p> 
      <h2> 12. Security.</h2>
      <p>We have implemented
      security safeguards to protect the personal information submitted to us,
      both during transmission and once we receive it. However, no method of
      transmission over the Internet or via mobile device, or method of
      electronic storage, is 100% secure - so we cannot guarantee its absolute
      security. You should take certain steps to protect your account and
      personal information as well, such as by choosing a strong password and
      keeping such password private, and logging out of your account when you
      are finished using it.
      </p> 
      <h2> 13. Links to Other Sites.</h2>
      <p> Our Site contains links
      to other sites that are not controlled by us. Please be aware that we are
      not responsible for the privacy practices of such other sites. We
      encourage you to be aware when you leave our site and read the privacy
      policies of each and every site you visit. This privacy policy applies
      only to information collected by our Site.</p>
      <h2> 14. Modifications and Updates
      to this Privacy Policy.</h2>
      <p>We may revise this privacy policy from time to
      time. The most current version of the privacy policy will govern our use
      of information about you and will be located
      here: https://Agrolinka.com/policy. If we make material changes to
      this privacy policy, we may notify you by email or by posting a notice on
      the Site. By continuing to access or use the Site after those changes
      become effective, you agree to be bound by the revised privacy policy.</p> 
      <h2>Contact Us</h2>
      <p>If you have questions or comments about Agrolinka privacy
      policy, please feel free to contact us at info@grolinka.com.</p> 
      <p> Last Updated
      May 31, 2022</p>
    </Row>
  );
};

export default PrivacyPolicy;
