import React from "react";
import Rating from "./Rating";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import "../styles/product.css";
import getSymbolFromCurrency from "currency-symbol-map";


const Product = ({ product }) => {
  const url = product.images && product.images.map((item, i) => item.url);

  return (
    <Card
      className="mt-3 p-0"
      style={{  borderRadius: "16px" }}
    >
      <Link to={`/product/${product._id}`}>
        <Card.Img
          style={{
            borderRadius: "20px",
            marginTop: "8px",
            marginLeft: "8px",
            marginRight: "8px",
            width: "280px",
            height: "280px",
            objectFit: "cover",
          }}
          loading="lazy"
          className="product-image"
          src={url.toString()}
          variant="top"
          alt={product.name}
        />
      </Link>

      <Card.Body>
        <Link
          to={`/product/${product._id}`}
          style={{ color: "dimgray", textDecoration: "none" }}
        >
          <Card.Title className="product-title" as="p" style={{marginTop: '0px', fontSize: '20px'}}>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as="div" style={{marginTop: '-56px'}}>
          {
            <Rating
              value={product.rating}
              text={`${product.numReviews} Review(s)${
                product.numReviews > 1 ? "s" : ""
              }`}
            />
          }
        </Card.Text>

        <Card.Text as="h4">
          {getSymbolFromCurrency("NGN")}
          {product.price}
        </Card.Text>

        <Card.Text style={{ color: "dimgray", textDecoration: "none" }}>
          <Card.Title className="product-title" as="p" >
            <strong>
              Store:{" "}
              <Link to={`/${product.storeName}`} className="storeLink">{product.storeName}</Link>
            </strong>
          </Card.Title>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
