import React from "react";
import { Row } from "react-bootstrap";

const Faq = () => {
  return (
    <Row style={{ maxWidth: "1024px", margin: "auto", textAlign: "justify" }}>
      <h1>FAQs</h1>
      <p>
     Continue to visit this page for updates on our FAQs

      </p>
    </Row>
  );
};

export default Faq;
