import React from "react";
import { Row } from "react-bootstrap";

const Careers = () => {
  return (
    <Row style={{ maxWidth: "1024px", margin: "auto", textAlign: "justify" }}>
      <h1>Careers</h1>
      <p>
      There are no open jobs at Agrolinka at the moment.

      </p>
    </Row>
  );
};

export default Careers;
