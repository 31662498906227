import React from "react";
import { Row } from "react-bootstrap";

const Contact = () => {
  return (
    <Row style={{ maxWidth: "1024px", margin: "auto", textAlign: "justify" }}>
      <h1>Contact Us</h1>
      <p>
        Experience smooth and stress free shopping, we are available 24 hours to
        respond to your enquiries. <br/>WRITE US <br/>info@agrolinka.com <br/>CALL US<br/>
        08065165064 <br/>ADDRESS <br/> 13, Akure Street, <br/>War College Estate, <br/>Gwarimpa,
        Abuja, Nigeria
      </p>
    </Row>
  );
};

export default Contact;
