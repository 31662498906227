import React from "react";
import { Row } from "react-bootstrap";

const RefundPolicy = () => {
  return (
    <Row style={{ maxWidth: "1024px", margin: "auto", textAlign: "justify" }}>
      <h1>Agrolinka Return Policy</h1>
      <h2>Purpose:</h2>
      <p>
        Agrolinka's return policy is designed to ensure a seamless and transparent process for returning goods, aligning with our commitment to quality, customer satisfaction, and efficient service. This policy helps to maintain trust and reliability with our vendors and customers, ensuring that all parties are fairly treated throughout the returns process.
      </p>
      <h2>Scope:</h2>
      <p>
        This policy applies to all vendors and customers engaged in buying and selling products on the Agrolinka platform. It covers the conditions under which items can be returned, the responsibilities of the sellers, and the process for handling returns.
      </p>
      <h2>1. Return Conditions:</h2>
      <h3>Eligibility for Returns:</h3>
      <ul>
        <li>Items are eligible for return within 30 days of delivery.</li>
        <li>Only items that are wrong, damaged, or defective upon receipt are eligible for return.</li>
        <li>Products must be in their original packaging, unused, and in the same condition as they were received.</li>
      </ul>
      <h3>Non-returnable Items:</h3>
      <ul>
        <li>Perishable goods such as food, fresh farm produce or plants.</li>
        <li>Customized products that are made to customer specifications.</li>
        <li>Personal care items and other goods that have health or sanitary concerns once opened.</li>
      </ul>
      <h2>2. Vendor Responsibilities:</h2>
      <h3>Quality Assurance:</h3>
      <ul>
        <li>Vendors are required to ensure that all items shipped meet the quality standards promised on the platform.</li>
        <li>Proper packaging must be used to prevent damage during transit.</li>
      </ul>
      <h3>Return Shipping Costs:</h3>
      <ul>
        <li>Vendors are responsible for the cost of return shipping if the item is wrong, damaged, or defective.</li>
        <li>If the customer returns an item due to a change of mind (where applicable), the customer will be required to cover return shipping costs.</li>
      </ul>
      <h2>3. Return Process:</h2>
      <h3>Initiating a Return:</h3>
      <ul>
        <li>Customers must contact Agrolinka support via the customer portal to initiate a return, providing a reason and evidence (e.g., photos) of the issue.</li>
        <li>A return merchandise authorization (RMA) number will be issued by Agrolinka after the return request is verified.</li>
      </ul>
      <h3>Shipping Returned Items:</h3>
      <ul>
        <li>Items must be securely packed and returned using the courier or shipping method advised by Agrolinka.</li>
        <li>The RMA number should be clearly visible on the return packaging.</li>
        <li>Customers must provide tracking information to Agrolinka once the item is shipped back.</li>
      </ul>
      <h2>4. Refunds and Replacements:</h2>
      <h3>Processing Returns:</h3>
      <ul>
        <li>Upon receipt of the returned item, Agrolinka will inspect the item to confirm the issue and process either a refund or replacement.</li>
        <li>If the return is approved, the customer can choose between a full refund to their original payment method or a replacement item.</li>
        <li>If a return is denied due to the item not meeting return conditions, the item will be sent back to the customer.</li>
      </ul>
      <h3>Timeline for Refunds:</h3>
      <ul>
        <li>Refunds are typically processed within 7-10 business days after receiving and inspecting the return.</li>
        <li>Customers will be notified via email once the refund has been processed.</li>
      </ul>
      <h2>5. Dispute Resolution:</h2>
      <p>
        In cases of disputes over returns, Agrolinka will mediate to find a fair resolution based on documentation provided by both parties. Our goal is to ensure fairness and customer satisfaction while upholding our quality standards.
      </p>
      <p>
        This return policy is part of Agrolinka's broader commitment to excellence and customer satisfaction, aiming to make every transaction on our platform a positive experience.
      </p>
    </Row>
  );
};

export default RefundPolicy;
