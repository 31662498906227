import React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import StarsIcon from '@mui/icons-material/Stars';
import { Link } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';

import {
	Row,
	Col,
	ListGroup	
} from 'react-bootstrap';


const Footer = () => {

 


  return (
    <>
      
        <>
          <footer>
          
     
            <Row style={{backgroundColor: '#010b01', paddingTop: '48px', marginTop: '16px' }}>
              <Row style={{maxWidth: '1200px', margin: 'auto'}}>
              <Col md={2} style={{color: '#f4fff4', cursor: 'pointer'}}>
                <ListGroup>
                  <h2> About </h2>
                  <Link to='/pages/contact'>
                    <p className='footer-link'> Contact Us </p>
                  </Link>
                  <Link to='/pages/about'>
                    <p className='footer-link'> About Us </p>
                  </Link>
                  <Link to='/pages/careers'>
                    <p className='footer-link'> Careers </p>
                  </Link>
                  {/* <p className='footer-link'> Corporate </p> */}
                </ListGroup>
              </Col>
              <Col md={2} style={{color: '#f4fff4', cursor: 'pointer'}}>
                <ListGroup>
                  <h2> Help </h2>
                  {/* <p className='footer-link'> Payments </p>
                  <p className='footer-link'> Shipping </p> */}
                  <Link to='pages/faq'>
                    <p className='footer-link'> FAQ </p>
                  </Link>
                  <Link to='/pages/unsubscribe'>
                    <p className='footer-link'> Unsubscribe Newsletter </p>
                  </Link>

                </ListGroup>
              </Col>
              <Col md={2} style={{color: '#f4fff4', cursor: 'pointer'}}>
                <ListGroup>
                  <h2> Policy </h2>
                  <Link to='/pages/policy'>
                    <p className='footer-link'>  Privacy Policy </p>
                  </Link>
                  <Link to='/pages/terms'>
                    <p className='footer-link'>  Terms </p>
                  </Link>
                  <Link to='/pages/refund-policy'>
                    <p className='footer-link'>  Refund Policy </p>
                  </Link>
                  <Link to='/pages/sellercontract'>
                    <p className='footer-link'>  Seller Contract </p>
                  </Link>
            
                </ListGroup>
              </Col>
              <Col md={2} style={{color: '#f4fff4', cursor: 'pointer'}}>
                <ListGroup>
                  <h2> Social </h2>
                  <a href='https://web.facebook.com/agrolinka'>
                    <p className='footer-link'> Facebook </p>
                  </a>
                  <a href='https://www.instagram.com/agrolinka/'>
                    <p className='footer-link'> Instagram </p>
                  </a>
                  <a href='https://twitter.com/agrolinka'>
                    <p className='footer-link'> Twitter </p>
                  </a>
                </ListGroup>
              </Col>
      
              <Col md={2} style={{color: '#f4fff4', cursor: 'pointer'}}>
              <h2>Mail Us:</h2>
                <p>Agrolinka Trades Limited,<br />
                  13 Akure Street <br />
                  War College Estate,<br />
                  Gwarimpa,<br />
                  FCT Abuja, 900101,<br />
                  Nigeria
                </p>
              </Col>
              <Col md={2} style={{color: '#f4fff4', cursor: 'pointer'}}>
              <h2>Office Address:</h2>
                <p >Agrolinka Trades Limited,<br />
                  13 Akure Street<br />
                  War College Estate,<br />
                  Gwarimpa,<br />
                  FCT Abuja, 900101<br />
                  Nigeria <br />
                  Telephone: <a  href="tel:+2348065165064" className='footer-phone'>+2348065165064</a>
                </p>
              </Col>
            </Row>
            </Row>
            

          </footer>
          {/* <!-- footer ends --> */}

          <Row style={{padding: '12px', backgroundColor: 'black'}}>

          
            <Row style={{maxWidth: '900px', margin: 'auto'}}>
            <Col md={3}>
              <a href="https://store.agrolinka.com/register" style={{color: '#fbfe46'}} className='footer-link'>
                <span><WorkIcon sx={{ fontSize: "20px", color: '#fbfe46'}} /></span> Create your store
              </a>
            </Col>

            <Col md={3}>
              <a href="https://agrolinka.com" style={{color: '#fbfe46'}} className='footer-link'>
                <span ><StarsIcon sx={{ fontSize: "20px", color: '#fbfe46' }} /></span> Advertise
              </a>
            </Col>
         
            <Col md={3}>
            <a href="https://agrolinka.com" style={{color: '#fbfe46'}} className='footer-link'>
              <span ><HelpIcon sx={{ fontSize: "20px", color: '#fbfe46' }} /></span> Help Center
            </a>
            </Col>


            <Col md={3}>
            <span style={{color: 'white'}}>&copy; {new Date().getFullYear()} Agrolinka.com</span>
            </Col>
            {/* <img draggable="false" src={paymentMethods} alt="Card Payment" /> */}
            </Row>
      
          </Row>
        </>
      
    </>
  )
};

export default Footer;


