import axios from "axios";
import {
  USER_SUBSCRIBE_REQUEST,
  USER_SUBSCRIBE_SUCCESS,
  USER_SUBSCRIBE_FAILURE,
  USER_UNSUBSCRIBE_REQUEST,
  USER_UNSUBSCRIBE_SUCCESS,
  USER_UNSUBSCRIBE_FAILURE,
} from "../constants/NewsletterConstants";

// Testing

export const subscribe = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_SUBSCRIBE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `https://agrolinka-from-heroku.herokuapp.com/api/user/newsletter/`,
      { email },
      config
    );

    dispatch({ type: USER_SUBSCRIBE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_SUBSCRIBE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// delete a particular product by taking an id
// export const userUnsubscribe = (email) => async (dispatch) => {
export const unsubscribe = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_UNSUBSCRIBE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.delete(
      `https://agrolinka-from-heroku.herokuapp.com/api/user/newsletter/${email}`,
      // `https://agrolinka-from-heroku.herokuapp.com/api/user/newsletter/`,
      config
    );
    console.log(data);
    data && dispatch({ type: USER_UNSUBSCRIBE_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_UNSUBSCRIBE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
