import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Form, FloatingLabel, Button } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { subscribe } from "../actions/NewsletterActions";
//import { PRODUCT_CREATE_RESET } from "../constants/productConstants";
import FormContainer from "../components/FormContainer";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const userSubscribe = useSelector((state) => state.userSubscribe);
  const { loading, success, error } = userSubscribe;

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!email) {
      alert('Please enter email')
    } else {
      dispatch(subscribe(email));
    }
  };
      

  return (
    <Row
      style={{
        backgroundColor: "#0b2603",
        maxWidth: "1256px",
        margin: "auto",
        borderRadius: "16px",
        marginTop: "24px",
        paddingBottom: "24px",
      }}
    >
      <FormContainer style={{ marginTop: "-2em" }}>
        <h2
          style={{
            textAlign: "center",
            color: "white",
            paddingTop: "24px",
          }}
        >
          Subscribe to Agrolinka Newsletter
        </h2>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message dismissible variant="danger" duration={10}>
            {error}
          </Message>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <FloatingLabel controlId="email" label="Email" className="mb-3">
                  <Form.Control
                    size="lg"
                    placeholder="Email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              {success && <Message variant='success' >{<p>Subscription Successful</p>}</Message>}
              <div className="d-flex">
                <Button
                  type="submit"
                  className="my-1 ms-auto"
                  style={{
                    borderRadius: "8px",
                    marginBottom: "16px",
                    backgroundColor: "green",
                    width: "200px",
                    padding: "12px",
                    border: "none",
                    margin: "auto",
                  }}
                >
                  Subscribe
                </Button>
              
              </div>
            </form>
          </>
        )}
      </FormContainer>
    </Row>
  );
};

export default Newsletter;

// import { Checkbox } from '@mui/material'
// import React, { useState } from 'react'
// import { Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap'
// import { useDispatch } from 'react-redux'
// import FormContainer from './FormContainer'
// import { userSubscribe } from '../actions/NewsletterActions'

// const NewletterSection = () => {

//     const [ email, setEmail ] = useState('')

//     const dispatch = useDispatch()

// const handleSubmit = e => {
//     e.preventDefault()
//     dispatch(userSubscribe(email))
// }

//   return (
//     <Row style={{backgroundColor: '#186300', borderRadius: '16px', maxWidth: '1200px', margin: 'auto'}}>
//         <Col  md={12} >

//             <FormContainer>
//             <Form onSubmit={handleSubmit}>
//             <h2 style={{ textAlign: 'center', paddingTop: '36px', color: 'white'}}>Subscribe to Agrolinka Newsletter</h2>
//             <Form.Group controlId='email'>
// 					<FloatingLabel
// 						controlId='email'
// 						label='Email'
// 						className='mb-3'>
// 						<Form.Control
// 							size='lg'
// 							placeholder='Enter email'
// 							type='text'
// 							value={email}
// 							required
// 							onChange={(e) => setEmail(e.target.value)}
// 						/>
// 					</FloatingLabel>
// 				</Form.Group>

//         <div style={{display: 'flex'}}>
//         <Checkbox style={{color: 'white'}}/>
//         <p style={{ textAlign: 'center', color: 'white'}}>I agree to receive this newsletter, and know that I can unsubscribe at anytime</p>
// 				</div>
//         <Button style={{margin: 'auto', backgroundColor: 'green'}} onSubmit={handleSubmit}>Subscribe</Button>
//             </Form>
//             </FormContainer>

//         </Col>

//         {/* <Col sm={12} md={6} lg={4} xl={3}>
//         </Col> */}
//     </Row>
//   )
// }

// export default NewletterSection
