import axios from "axios";

import {
  STORE_DETAILS_FAILURE,
  STORE_DETAILS_SUCCESS,
  STORE_DETAILS_REQUEST,
} from "../constants/storeConstants";

export const getStore = (storeName) => async (dispatch) => {
  try {
    dispatch({ type: STORE_DETAILS_REQUEST });

    const { data } = await axios.get(
      `https://agrolinka-from-heroku.herokuapp.com/api/user/stores/${storeName}`
    );

    dispatch({ type: STORE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: STORE_DETAILS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
